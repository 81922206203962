import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createHead } from "@vueuse/head";
import "@/assets/styles/index.sass";

const app = createApp(App);
const head = createHead();

app.use(router).use(head).mount("#app");
