<template>
  <header id="header">
    <div class="content">
      <!--      <router-link to="/" class="logo center">-->
      <!--        <img src="@/assets/images/icons/logo.svg" alt="Логотип" />-->
      <!--      </router-link>-->
      <button class="burger" v-if="!showMenu" @click="toggleMenu">
        <img src="@/assets/images/icons/burger.svg" alt="Открыть меню" />
      </button>
      <button class="burger-close" v-else @click="toggleMenu">
        <img
          src="@/assets/images/icons/close-menu-burger.svg"
          alt="Закрыть меню"
        />
      </button>
      <transition name="wrapper">
        <div v-if="showMenu" class="menu-wrapper" @click="toggleMenu">
          <div class="menu" @click.stop>
            <button class="close-btn" @click="toggleMenu">
              <img
                src="@/assets/images/icons/close-menu.svg"
                alt="Закрыть меню"
              />
            </button>
            <vNav class="menu-nav" @menu-close="closeMenu" />
          </div>
        </div>
      </transition>
      <transition name="menu">
        <div v-if="showMenu" class="menu-tablet">
          <vNav class="menu-nav" />
        </div>
      </transition>
    </div>
  </header>
</template>

<script>
import vNav from "@/components/UI/v-Nav.vue";

export default {
  components: { vNav },
  data() {
    return {
      showMenu: false,
      vacancy: "",
    };
  },
  watch: {
    vacancy(value) {
      this.$emit("send-value", value);
    },
  },
  methods: {
    closeMenu(a) {
      this.vacancy = a;
      this.showMenu = !this.showMenu;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/styles/base/adaptive'
.wrapper-enter-active
  transition: all 0.4s ease-out
.wrapper-leave-active
  transition: all 0.3s ease
.wrapper-enter-from,
.wrapper-leave-to
  opacity: 0

.menu-enter-active
  transition: all 0.4s ease-out
.menu-leave-active
  transition: all 0.3s ease
.menu-enter-from,
.menu-leave-to
  transform: translateX(100svw)

#header
  position: relative
  .content
    display: flex
    align-items: center
    padding-top: 26px
    padding-bottom: 26px
    justify-content: space-between
    .burger-close
      opacity: 0
      transition: .3s ease
    .menu-wrapper
      position: fixed
      top: 0
      left: 0
      background: rgba(24, 24, 24, 0.60)
      width: 100svw
      height: 100svh
      z-index: 2
      .menu
        background: var(--white-color)
        position: absolute
        z-index: 100
        width: fit-content
        height: 100svh
        padding: 42px 76px
        top: 0
        right: 0
        display: flex
        flex-direction: column
        gap: 140px
        .close-btn
          padding: unset
        .menu-nav
          display: flex
          flex-direction: column
    .menu-tablet
      display: none
@media only screen and (max-width: $bp-pc)
  #header
    .content
      padding-top: 18px
      padding-bottom: 18px
      .logo
        img
          width: 54px
@media only screen and (max-width: $bp-tablet)
  #header
    .content
      padding: 10px 15px
      gap: unset
      .burger-close
        opacity: 1
        transition: .3s ease
        img
          width: 42px
          height: 24px
      .burger
        img
          width: 42px
          height: 24px
      .logo
        img
          width: 44px
          height: 44px
      .menu-tablet
        display: block
        position: fixed
        left: 0
        background: var(--white-color)
        width: 100svw
        z-index: 2
        top: 64px
        height: calc(100svh - 64px)
        padding: 90px 15px
        .menu-nav
          display: flex
          flex-direction: column
      .menu-wrapper
        display: none
</style>
