<template>
  <nav class="nav">
    <router-link @click="closeMenu($event)" to="/#form-section" class="link">
      <p class="text">Повар-кассир</p>
      <span class="underline"></span>
    </router-link>
    <router-link @click="closeMenu($event)" to="/#form-section" class="link">
      <p class="text">Курьер</p>
      <span class="underline"></span>
    </router-link>
    <!--    <router-link @click="closeMenu($event)" to="/#form-section" class="link">-->
    <!--      <p class="text">Ночной уборщик</p>-->
    <!--      <span class="underline"></span>-->
    <!--    </router-link>-->
    <!--    <router-link @click="closeMenu($event)" to="/#form-section" class="link">-->
    <!--      <p class="text">Дневной уборщик</p>-->
    <!--      <span class="underline"></span>-->
    <!--    </router-link>-->
  </nav>
</template>

<script>
export default {
  emits: ["menu-close"],
  methods: {
    closeMenu(event) {
      const parent = event.target.closest(".link");
      const text = parent.querySelector(".text").textContent;
      this.$emit("menu-close", text);
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/styles/base/adaptive'
.nav
  display: flex
  gap: 20px
  width: 448px
  .link
    display: flex
    width: fit-content
    flex-direction: column
    gap: 11px
    .text
      font-size: 2rem
      font-weight: 700
      line-height: 100%
    .underline
      opacity: 0
      transition: .3s ease
      height: 4px
      background: var(--main-color)
      width: 100%
    &:hover
      .underline
        transition: .3s ease
        opacity: 1
@media only screen and (max-width: $bp-tablet)
  .nav
    display: flex
    gap: 19px
    width: unset
    .link
      display: flex
      flex-direction: column
      gap: unset
      .text
        font-size: 1.5rem
      .underline
        display: none
</style>
