<template>
  <vHeader @send-value="getVacancy" />
  <router-view :vacancy="vacancy"></router-view>
  <vFooter />
</template>

<script>
import vHeader from "@/components/UI/v-Header.vue";
import vFooter from "@/components/UI/v-Footer.vue";

export default {
  components: {
    vFooter,
    vHeader,
  },
  data() {
    return {
      vacancy: "",
    };
  },
  methods: {
    getVacancy(a) {
      this.vacancy = a;
    },
  },
};
</script>

<style lang="sass">
#app
</style>
